import React, { Component } from 'react'
import { SnackbarContent } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  snackBarStyle: {
    backgroundColor: '#69ba41',
    position: 'absolute',
    top: '15px',
    left: '50%',
    transform: 'translatex(-50%)'
  },
  errorStyle: {
    backgroundColor: '#ff0000',
    position: 'absolute',
    top: '15px',
    left: '50%',
    transform: 'translatex(-50%)'
  }
}

class SnackbarWrapper extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showSnackbar: false
    }
  }

  componentDidMount () {
    console.log('showing snackbar')
    this.setState({ showSnackbar: true })

    this.hideSnackbarTimer = setTimeout(() => {
      this.setState(() => ({ showSnackbar: false }))
      this.callbackTimer = setTimeout(() => {
        this.props.onSnackbarHide()
      }, 100)
    }, 5000)
  }

  componentWillUnmount () {
    clearTimeout(this.hideSnackbarTimer)
    clearTimeout(this.callbackTimer)
  }

  render () {
    const { classes, message, isError } = this.props
    return (
      <>
        {
          this.state.showSnackbar &&
            <SnackbarContent
              className={isError ? classes.errorStyle : classes.snackBarStyle}
              message={
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <CheckCircle />&nbsp;{message}
                </span>
              }
              onClick={() => this.setState({ showSnackbar: false })}
            />
        }
      </>
    )
  }
}

export default withStyles(styles)(SnackbarWrapper)
