import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  imageStyle: {
    height: '432px',
    width: '280px',
    objectFit: 'contain'
  }
}

class ImageHandler extends Component {
  render () {
    const { classes, info, selected, imageServerBaseUrl } = this.props
    const item = info.categories[0].items[selected]
    let imagePath = ''
    if (item) {
      console.log('ImageHandler.render', item)
      imagePath = `${imageServerBaseUrl}/imageproxy/information/image/${item.image}/large`
    }

    console.log('imagePath', imagePath)

    return (
      <img
        src={imagePath}
        alt='bild saknas'
        className={classes.imageStyle}
      />
    )
  }
}

export default withStyles(styles)(ImageHandler)
