import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

var bottomBarStyle = {
  display: 'flex',
  margin: 'auto',
  backgroundColor: '#303033',
  color: '#ffffff',
  width: '100%',
  height: '65px',
  boxShadow: '0 -5px 6px 0 rgb(0, 0, 0, 0.16)',
  zIndex: 2000,
  verticalAlign: 'center',
  position: 'fixed',
  bottom: '0'
}

var bottomBarTextStyle = {
  margin: 'auto',
  textAlign: 'center'
}

const styles = {
  bottomBarStyle,
  bottomBarTextStyle
}

class Bottombar extends Component {
  render () {
    const { text, classes } = this.props

    return (
      <div className={classes.bottomBarStyle}>
        <span className={classes.bottomBarTextStyle}>
          {text}
        </span>
      </div>
    )
  }
}

export default withStyles(styles)(Bottombar)
