module.exports = Object.freeze(require('rc')('eagle-region-tool', {
  client: {
    api: {
      address: 'https://infoanslag.kalejdo.tv'
    },
    url: 'https://infoanslag.kalejdo.tv'
  },
  db: {
    host: 'kal-db2',
    user: 'iptv',
    password: 'p4rkDr34m',
    database: 'eagleinfo',
    port: 3306
  },
  server: {
    express: {
      port: 4003
    },
    jwt: {
      secret: 'aasdinas76g6vghTRsdf'
    },
    imageServer: 'http://localhost:4454'
  },
  validImageExtensions: ['.jpg', '.jpeg']
}))
