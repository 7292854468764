import React, { Component } from 'react'
import { Grid, TextField, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { client } from '../config'

const conf = client

const fetch = require('node-fetch')

const styles = {
  loginStyle: {
    height: '100vh',
    display: 'grid',
    backgroundColor: '#e0e0e0'
  },
  gridStyle: {
    width: '400px',
    margin: 'auto',
    padding: '25px',
    borderRadius: '5px',
    backgroundColor: '#fff',
    boxShadow: '0 0 6px 3px rgb(0, 0, 0, 0.1)'
  },
  textFieldStyle: {
    width: '100%',
    paddingBottom: '25px'
  },
  buttonStyle: {
    width: '100%',
    backgroundColor: '#454c59',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#d5d5d5'
    }
  },
  errorStyle: {
    backgroundColor: '#d68d8d',
    height: '50px',
    borderRadius: '5px',
    marginBottom: '25px'
  },
  loginHeader: {
    fontSize: '2em',
    marginBottom: '25px'
  }
}

class Login extends Component {
  constructor (props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      error: {
        show: false,
        message: 'Inloggning misslyckades'
      }
    }

    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  componentDidMount () {
    if (localStorage.length) { // eslint-disable-line
      const context = JSON.parse(localStorage.getItem('context')) // eslint-disable-line
      this.props.onLocalStorageContext(context)
    }
  }

  handleLogin () {
    fetch(`${conf.api.address}/login`, {
      method: 'POST',
      headers: {
        mode: 'cors',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({
        user: this.state.email,
        password: this.state.password
      })
    })
      .then(res => res.json())
      .then((res) => {
        console.log(res)
        if (res.status === '200') {
          localStorage.setItem('context', JSON.stringify(res.value)) // eslint-disable-line
          this.props.onToggleLoginStatus(res)
        } else {
          throw new Error('sign-in failed')
        }
      })
      .catch((err) => {
        this.setState({ error: { show: true, message: `${err}` } })
      })
  }

  handleChange (prop) {
    return (e) => {
      const { value } = e.target
      this.setState({
        [prop]: value
      })
    }
  }

  handleKeyDown (e) {
    const { email, password } = this.state
    if (e.key === 'Enter' && email.length && password.length) {
      this.handleLogin()
    }
  }

  render () {
    const { classes } = this.props

    return (
      <div className={classes.loginStyle}>
        <Grid container spacing={0} direction='row' className={classes.gridStyle}>
          {/* <Grid item m={0} p={0} xs={12}>
            <h1>Login</h1>
          </Grid> */}
          {
            this.state.error.show &&
              <Grid item m={0} p={0} xs={12} className={classes.errorStyle}>
                <p style={{ verticalAlign: 'middle' }}>{this.state.error.message}</p>
              </Grid>
          }
          <Grid item m={0} p={0} xs={12}>
            <img src='kalejdo_logo.gif' />
            <div className={classes.loginHeader}>Inlogging</div>
          </Grid>
          <Grid item m={0} p={0} xs={12}>
            <TextField
              variant='outlined'
              placeholder='user'
              value={this.state.email}
              className={classes.textFieldStyle}
              onChange={this.handleChange('email')}
              onKeyDown={this.handleKeyDown}
            />
          </Grid>
          <Grid item m={0} p={0} xs={12}>
            <TextField
              variant='outlined'
              placeholder='password'
              type='password'
              value={this.state.password}
              className={classes.textFieldStyle}
              onChange={this.handleChange('password')}
              onKeyDown={this.handleKeyDown}
            />
          </Grid>
          <Grid item m={0} p={0} xs={12}>
            <Button
              className={classes.buttonStyle}
              onClick={() => this.handleLogin()}
            >
              Logga in
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(Login)
