import React, { Component } from 'react'
import './App.css'
import Workspace from './components/Workspace'
import Login from './components/Login'

class App extends Component {
  constructor (props) {
    super(props)

    this.state = {
      mode: 'info',
      loggedIn: false,
      context: {
        region: null,
        user: null,
        token: null
      }
    }

    this.handleToggleLoginStatus = this.handleToggleLoginStatus.bind(this)
    this.handleLocalStorageContext = this.handleLocalStorageContext.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handleModeChanged = this.handleModeChanged.bind(this)
  }

  handleLocalStorageContext (context) {
    this.setState({
      loggedIn: true,
      context
    })
  }

  handleToggleLoginStatus ({ value }, context) {
    const { region, user, token } = value

    this.setState({
      loggedIn: true,
      context: {
        region,
        user,
        token
      }
    })
  }

  handleModeChanged () {
    this.setState({ mode: (this.state.mode === 'info') ? 'message' : 'info' })
  }

  handleLogout () {
    localStorage.removeItem('context') // eslint-disable-line
    this.setState({
      loggedIn: false,
      context: null
    })
  }

  render () {
    let component
    if (this.state.loggedIn === true) {
      component =
        <Workspace
          context={this.state.context}
          mode={this.state.mode}
          key={this.state.mode}
          onModeChanged={this.handleModeChanged}
          onLogout={this.handleLogout}
        />
    } else {
      component =
        <Login
          onToggleLoginStatus={this.handleToggleLoginStatus}
          onLocalStorageContext={this.handleLocalStorageContext}
        />
    }

    return (
      <div className='App' style={{ overflow: 'hidden' }}>
        {component}
      </div>
    )
  }
}

export default App
