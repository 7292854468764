import React, { Component } from 'react'
import { Paper, Grid, Button, FormLabel, TextField, Tooltip } from '@material-ui/core'
import { Edit, Language, InsertPhoto, Cancel } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import PersistentDrawer from './PersistentDrawer'
import EditorWrapper from './EditorWrapper'
import ContentTypes from '../ContentType'
import ImageHandler from './ImageHandler'
// import ImageSelector from './ImageSelector'
import SnackbarWrapper from './SnackbarWrapper'

const dateFormat = require('dateformat')

const styles = {
  bottomInfoStyle: {
    borderTop: '1px solid rgba(0, 0, 0,  0.16)',
    paddingTop: '15px',
    marginTop: '10px'
  },
  buttonStyle: {
    backgroundColor: '#454c59',
    color: 'white'
  },
  contentWrapperStyle: {
    // height: '100%',
    flexFlow: 'column'
  },
  deleteButtonStyle: {
    float: 'right',
    color: 'white',
    marginRight: '25px',
    backgroundColor: '#bd2828',
    '&:hover': {
      backgroundColor: '#d68585'
    }
  },
  formLabelStyle: {
    fontSize: '12px',
    color: '#106ebe'
  },
  formLabelContainerStyle: {
    marginTop: '10px',
    clear: 'both',
    float: 'left'
  },
  headerGridStyle: {
    height: '25%'
  },
  headerPaperStyle: {
    padding: '5px 25px 15px 25px',
    height: '90%'
  },
  headerStyle: {
    fontWeight: 'normal'
  },
  inputStyle: {
    visibility: 'hidden'
  },
  inputFileButtonStyle: {
    width: '100%',
    backgroundColor: '#454c59',
    color: 'white',
    '&:hover': {
      backgroundColor: '#d5d5d5'
    }
  },
  imageStyle: {
    height: '296px',
    width: '196px'
  },
  imageContainerStyle: {
    width: '100%',
    height: '92%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  mainGridStyle: {
    height: '90%'
  },
  obligatoryStyle: {
    color: 'red'
  },
  obligatoryTextStyle: {
    color: 'red',
    float: 'left'
  },
  paperStyle: {
    padding: '5px 25px 15px 25px',
    marginBottom: '3%',
    height: '100%',
    minWidth: '300px'
  },
  paperContentContainerStyle: {
    width: '100%',
    height: '80%',
    textAlign: 'left',
    display: 'inline-block',
    verticalAlign: 'top'
  },
  paperTitleStyle: {
    float: 'left'
  },
  paperTitleContainerStyle: {
    width: '100%',
    height: '40px',
    textAlign: 'left'
  },
  publishButtonStyle: {
    float: 'right',
    backgroundColor: '#454c59',
    color: 'white'
  },
  textGridStyle: {
    height: '100%',
    justifyContent: 'flex-end'
  },
  textEditorGridItemStyle: {
    height: '70%'
  },
  textEditorStyle: {
    height: '150px'
  },
  textEditorWrapperStyle: {
    width: '82%',
    height: '97%',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'rgba(0, 0, 0, 0.26)',
    borderRadius: '5px',
    padding: '0 15px 0 15px',
    margin: '0',
    float: 'left'
  },
  textEditorHiddenAreaStyle: {
    visibility: 'hidden',
    float: 'left'
  },
  textFieldStyle: {
    minWidth: '84%',
    width: '84%'
  },
  textFieldButtonStyle: {
    backgroundColor: '#454c59',
    color: 'white',
    float: 'right',
    width: '12%',
    height: '32px'
  }
}

const textFieldInputProps = {
  style: {
    height: '27px',
    padding: '5px',
    margin: 0,
    minWidth: '100%',
    width: '100%'
  }
}

class Content extends Component {
  constructor (props) {
    super(props)

    this.state = {
      titleFieldDisabled: true,
      synopsisFieldDisabled: true,
      errorMsg: ''
    }

    this.fileDialogRef = React.createRef()

    this.handleTitleChanged = this.handleTitleChanged.bind(this)
    this.handleContentChanged = this.handleContentChanged.bind(this)
    this.handleUploadImage = this.handleUploadImage.bind(this)
    this.handleSelectedChanged = this.handleSelectedChanged.bind(this)
    this.handleFileDialog = this.handleFileDialog.bind(this)
    this.handleSnackbarHide = this.handleSnackbarHide.bind(this)
  }

  handleTitleChanged (e) {
    this.props.onContentChanged(ContentTypes.TITLE, e.target.value)
  }

  handleContentChanged (type, data) {
    this.props.onContentChanged(type, data)
  }

  handleSelectedChanged (selected) {
    this.props.onSelectedChanged(selected)

    if (this.state.titleFieldDisabled === false) {
      this.setState({ titleFieldDisabled: true })
    } else if (this.state.synopsisFieldDisabled === false) {
      this.setState({ synopsisFieldDisabled: true })
    }
  }

  handleToggleTextFieldDisabled (type) {
    let disabled
    switch (type) {
      case ContentTypes.TITLE:
        disabled = this.state.titleFieldDisabled
        this.setState({
          titleFieldDisabled: disabled === false
        })
        break
      case ContentTypes.SYNOPSIS:
        disabled = this.state.synopsisFieldDisabled
        this.setState({
          synopsisFieldDisabled: disabled === false
        })
        break
      // no default
    }
  }

  handleUploadImage (e) {
    this.props.onImageChanged(e.target.files)
    // image: this.props.info.categories[0].items[this.props.selected].image
  }

  handleSelectImage (image) {
    console.log('handleSelectImage', image)
  }

  handleFileDialog () {
    this.fileDialogRef.current.click()
  }

  handlePublish () {
    this.props.onPostState()
  }

  renderEmptyContent () {
    const { classes, mode } = this.props

    return (
      <Grid container direction='row' spacing={3} className={classes.mainGridStyle}>
        <Grid item xs={12} style={{ minHeight: '584px' }}>
          <Paper className={classes.headerPaperStyle} style={{ minHeight: '140px' }}>
            <div className={classes.paperTitleContainerStyle}>
              <h2>
                {mode === 'info' ? 'Klicka på "Lägg till ny menyrad" för att lägga till ett första menyalternativ!' : 'Klicka på "Lägg till ny anslag" för att lägga till ett första anslag!'}
              </h2>
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }

  renderContent () {
    const { classes, selected, info, mode } = this.props
    let items, imagePublishDate, titlePublishDate, synopsisPublishDate, selectedItem

    if (!info.categories || info.categories.length === 0) {
      return null
    } else {
      items = info.categories[0].items

      selectedItem = items[selected]

      var date = new Date()

      if (selectedItem && selectedItem.imagePublishDate) {
        date.setTime(selectedItem ? selectedItem.imagePublishDate : Date.now())
        imagePublishDate = dateFormat(
          date,
          'yyyy-mm-dd HH:MM'
        )
      }

      if (selectedItem && selectedItem.titlePublishDate) {
        date.setTime(selectedItem ? selectedItem.titlePublishDate : Date.now())
        titlePublishDate = dateFormat(
          date,
          'yyyy-mm-dd HH:MM'
        )
      }

      if (selectedItem && selectedItem.synopsisPublishDate) {
        date.setTime(selectedItem ? selectedItem.synopsisPublishDate : Date.now())
        synopsisPublishDate = dateFormat(
          date,
          'yyyy-mm-dd HH:MM'
        )
      }
    }

    return (
      <Grid container direction='row' spacing={3} className={classes.mainGridStyle}>
        {
          this.props.mode === 'info' &&
            <Grid item xs={3} style={{ minHeight: '560px' }}>
              <Paper className={classes.paperStyle}>
                <div className={classes.paperTitleContainerStyle}>
                  <Tooltip title={mode === 'info' ? ' Bild skall vara av format jpeg/jpg och kommer att beskäras till 196 px i bredd samt 296 px i höjd.' : ''}>
                    <h2>Sidbild <span className={classes.obligatoryStyle}>*</span></h2>
                  </Tooltip>
                  {/* <FormLabel className={classes.formLabelStyle}>{mode === 'info' ? ' Bild skall vara av format jpeg/jpg och kommer att beskäras till 196 px i bredd samt 296 px i höjd.' : ''}</FormLabel> */}
                </div>
                <div className={classes.paperContentContainerStyle}>
                  <div className={classes.imageContainerStyle}>
                    {/* <img
                          src={`/images/${info.categories[0].items[selected].image}`}
                          alt='info page'
                          className={classes.imageStyle}
                        /> */}
                    <ImageHandler
                      selected={selected}
                      info={info}
                      localImages={this.props.localImages}
                      imageServerBaseUrl={this.props.imageServerBaseUrl}
                    />
                  </div>
                  <input
                    type='file'
                    name='image'
                    onChange={(e) => this.handleUploadImage(e)}
                    ref={this.fileDialogRef}
                    className={classes.inputStyle}
                  />
                  {/* <ImageSelector
                    selected={selected}
                    info={info}
                    imageServerBaseUrl={this.props.imageServerBaseUrl}
                    onImageSelect={(e) => this.handleSelectImage(e)}
                    regionName={region}
                  /> */}
                  <Tooltip title={mode === 'info' ? ' Bild skall vara av format jpeg/jpg och kommer att beskäras till 196 px i bredd samt 296 px i höjd.' : ''}>
                    <Button
                      className={classes.inputFileButtonStyle}
                      onClick={this.handleFileDialog}
                    >
                      <InsertPhoto />&nbsp;Ladda upp bild
                    </Button>
                  </Tooltip>
                  <br />
                  <div className={classes.formLabelContainerStyle}>
                    <FormLabel className={classes.formLabelStyle}>{imagePublishDate ? 'Uppdaterad ' + imagePublishDate : 'Ej uppdaterad'}</FormLabel>
                  </div>
                </div>
              </Paper>
            </Grid>
        }
        <Grid item xs={(this.props.mode === 'info') ? 9 : 12} style={{ minHeight: '584px' }}>
          <Grid container direction='row' spacing={0} className={classes.textGridStyle}>
            <Grid item xs={12} className={classes.headerGridStyle}>
              <Paper className={classes.headerPaperStyle} style={{ minHeight: '140px' }}>
                <div className={classes.paperTitleContainerStyle}>
                  <h2>{mode === 'info' ? 'Titel på menyrad' : 'Titel på anslag'} <span className={classes.obligatoryStyle}>*</span></h2>
                </div>
                <div className={classes.paperContentContainerStyle}>
                  <div>
                    <TextField
                      variant='outlined'
                      disabled={this.state.titleFieldDisabled}
                      className={classes.textFieldStyle}
                      inputProps={textFieldInputProps}
                      placeholder='Titel'
                      value={items[selected] ? items[selected].title : ''}
                      onChange={
                        (e) => this.handleTitleChanged(e)
                      }
                    />
                    <Button variant='contained' className={classes.textFieldButtonStyle} onClick={() => this.handleToggleTextFieldDisabled(ContentTypes.TITLE)}>
                      <Edit />&nbsp;Ändra
                    </Button>
                  </div>
                  <div className={classes.formLabelContainerStyle}>
                    <FormLabel className={classes.formLabelStyle}>{titlePublishDate ? 'Uppdaterad ' + titlePublishDate : 'Ej uppdaterad'}</FormLabel>
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} className={classes.textEditorGridItemStyle} style={{ minHeight: '392px', marginTop: '28px' }}>
              <Paper className={classes.paperStyle}>
                <div className={classes.paperTitleContainerStyle}>
                  <h2>{mode === 'info' ? 'Text i menyrad' : 'Text i anslag'} <span className={classes.obligatoryStyle}>*</span></h2>
                </div>
                <div className={classes.paperContentContainerStyle}>
                  <EditorWrapper
                    key={selected}
                    selected={selected}
                    info={items}
                    synopsisFieldDisabled={this.state.synopsisFieldDisabled}
                    onContentChanged={this.handleContentChanged}
                  />
                  <Button variant='contained' className={classes.textFieldButtonStyle} onClick={() => this.handleToggleTextFieldDisabled(ContentTypes.SYNOPSIS)}>
                    <Edit />&nbsp;Ändra
                  </Button>
                  <div className={classes.formLabelContainerStyle}>
                    <FormLabel className={classes.formLabelStyle}>{synopsisPublishDate ? 'Uppdaterad ' + synopsisPublishDate : 'Ej uppdaterad'}</FormLabel>
                  </div>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          <div className={classes.bottomInfoStyle}>
            <FormLabel className={classes.obligatoryTextStyle}>{selectedItem ? '* Obligatoriska fält' : 'Lägg till nytt element i menyn'}</FormLabel>
            <Button
              variant='contained'
              className={classes.publishButtonStyle}
              onClick={(e) => this.handlePublish()}
            >
              <Language />&nbsp;Uppdatera
            </Button>
            <Button
              variant='contained'
              className={classes.deleteButtonStyle}
              onClick={(e) => this.props.onRemoveInfoPage()}
            >
              <Cancel />&nbsp;{(mode === 'info') ? 'Ta bort menyrad' : 'Ta bort anslag'}
            </Button>
          </div>
        </Grid>
      </Grid>
    )
  }

  handleSnackbarHide () {
    this.setState({ errorMsg: '' })
  }

  renderErrorSnackbar (errorMsg) {
    if (!errorMsg || errorMsg === '') return null
    console.log('render error snackbar', errorMsg)
    return (
      <SnackbarWrapper
        message={errorMsg}
        onSnackbarHide={this.handleSnackbarHide}
        isError
      />
    )
  }

  render () {
    const { classes, selected, info, initial, mode, context, onModeChanged, onAddInfoPage, onRemoveInfoPage, errorMsg } = this.props
    if (!info.categories || info.categories.length === 0) {
      return null
    }

    const items = info.categories[0].items

    return (
      <div className={classes.contentWrapperStyle}>
        <PersistentDrawer
          selected={selected}
          initial={initial}
          info={info}
          context={context}
          mode={mode}
          onModeChanged={onModeChanged}
          onSelectedChanged={this.handleSelectedChanged}
          onAddInfoPage={onAddInfoPage}
          onRemoveInfoPage={onRemoveInfoPage}
        >
          {this.renderErrorSnackbar(errorMsg)}
          {items[selected] ? this.renderContent() : this.renderEmptyContent()}
        </PersistentDrawer>
      </div>
    )
  }
}

export default withStyles(styles)(Content)
