
import React, { Component } from 'react'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  alertWindowStyle: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    height: '170px',
    width: '400px',
    marginTop: '-85px',
    marginLeft: '-200px',
    backgroundColor: '#e0e0e0',
    boxShadow: '0 0 6px 3px rgb(0, 0, 0, 0.25)',
    zIndex: 9001
  },
  headerStyle: {
    color: '#000'
  },
  buttonStyle: {
    color: '#fff',
    backgroundColor: '#454c59',
    marginRight: '25px'
  }
}

class ConfirmationAlert extends Component {
  render () {
    const { classes, info, selected } = this.props

    return (
      <div className={classes.alertWindowStyle}>
        <h1 className={classes.headerStyle}>Bekräfta borttagning</h1>
        <p>Är du säker på att sidan '{info.categories[0].items[selected].title}' ska tas bort?</p>
        <Button
          className={classes.buttonStyle}
          onClick={this.props.onRemoveInfoPage}
        >
          Ja
        </Button>
        <Button
          className={classes.buttonStyle}
          onClick={this.props.onCancel}
        >
          Avbryt
        </Button>
      </div>
    )
  }
}

export default withStyles(styles)(ConfirmationAlert)
