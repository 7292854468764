import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { Editor } from 'react-draft-wysiwyg'
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import ContentTypes from '../ContentType'

const styles = {
  textEditorStyle: {
    height: '240px'
  },
  textEditorDisabledStyle: {
    height: '240px',
    color: 'rgba(0, 0, 0, 0.38)'
  },
  textEditorWrapperStyle: {
    width: '82%',
    height: '97%',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'rgba(0, 0, 0, 0.26)',
    borderRadius: '5px',
    padding: '0 15px 0 15px',
    margin: '0',
    float: 'left'
  }
}

const toolbarOptions = {
  options: ['inline', 'history'],
  inline: {
    options: ['bold', 'underline', 'strikethrough', 'superscript', 'subscript']
  }
}

class EditorWrapper extends Component {
  constructor (props) {
    super(props)

    this.state = {
      editorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(this.props.info[this.props.selected] ? this.props.info[this.props.selected].synopsis : '')
        )
      )
    }

    this.handleContentStateChange = this.handleContentStateChange.bind(this)
  }

  handleContentStateChange (editorState) {
    this.setState({ editorState })
    this.props.onContentChanged(
      ContentTypes.SYNOPSIS,
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    )
  }

  render () {
    const { classes } = this.props
    const { editorState } = this.state

    return (
      <Editor
        editorState={editorState}
        editorClassName={this.props.synopsisFieldDisabled === true ? classes.textEditorDisabledStyle : classes.textEditorStyle}
        wrapperClassName={classes.textEditorWrapperStyle}
        onEditorStateChange={(editorState) => this.handleContentStateChange(editorState)}
        toolbar={toolbarOptions}
        readOnly={this.props.synopsisFieldDisabled}
        placeholder='Text'
      />
    )
  }
}

export default withStyles(styles)(EditorWrapper)
