import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { Lock } from '@material-ui/icons'

const styles = {
  topBarStyle: {
    display: 'flex',
    margin: 'auto',
    backgroundColor: '#fafafa',
    width: '100%',
    height: '83px',
    boxShadow: '0 3px 6px 0 rgb(0, 0, 0, 0.16)',
    verticalAlign: 'middle'
  },
  topBarLeftWrapperStyle: {
    margin: 'auto',
    justifyContent: 'stretch',
    width: '100%'
  },
  topBarLeftStyle: {
    float: 'left',
    height: '83px',
    paddingLeft: '25px',
    paddingTop: '15px'
  },
  topBarHeaderStyle: {
    display: 'inline-block',
    padding: '0',
    position: 'fixed',
    top: '15px',
    left: '300px'

  },
  topBarButtonStyle: {
    float: 'right',
    backgroundColor: '#454c59',
    width: '146px',
    height: '32px',
    fontFamily: 'SegoeUI, sans-serif',
    color: '#fff',
    marginRight: '24px',
    marginTop: '25px'
  }
}

class Topbar extends Component {
  render () {
    const { text, classes } = this.props

    return (
      <div className={classes.topBarStyle}>
        <span className={classes.topBarLeftWrapperStyle}>
          <span className={classes.topBarLeftStyle}>
            <img src='../../kalejdo_logo.gif' alt='logo' />
            <h1 className={classes.topBarHeaderStyle}>{text}</h1>
          </span>
          <Button
            variant='contained'
            className={classes.topBarButtonStyle}
            onClick={this.props.onLogout}
          >
            <Lock />&nbsp;Logout
          </Button>
        </span>
      </div>
    )
  }
}

export default withStyles(styles)(Topbar)
